<script lang="ts">
    import { createAvatar, melt } from '@melt-ui/svelte';
    import { createEventDispatcher } from 'svelte';
		import type { ToWritableStores, ChangeFn } from '@melt-ui/svelte/internal/helpers';
	import type { Invalidator, Subscriber, Unsubscriber, Updater, Writable } from 'svelte/store';

    export let src = '';
    export let username = '';
    export let delayMs = 50;

    export let size: 'small' | 'medium' | 'large' | 'smallest' = 'medium';

    const dispatch = createEventDispatcher();

    $: text_fallback = username ? username.substring(0, 2).toUpperCase() : '';
  
    // const text_fallback = derived(username, ($username) => {
    //   return $username.substring(0, 2).toUpperCase();
    // });

    let avatar: {
		elements: any; states?: {
			loadingStatus: {
				update: (updater: Updater<"loading" | "error" | "loaded">, sideEffect?: ((newValue: "loading" | "error" | "loaded") => void) | undefined) => void; set: (this: void, value: "loading" | "error" | "loaded") => void; subscribe(this: void, run: Subscriber<"loading" | "error" | "loaded">, invalidate?: Invalidator<
					// const text_fallback = derived(username, ($username) => {
					//   return $username.substring(0, 2).toUpperCase();
					// });
					"loading" // const text_fallback = derived(username, ($username) => {
					//   return $username.substring(0, 2).toUpperCase();
					// });
					| "error" | "loaded"> | undefined): Unsubscriber; get: () =>
						//   return $username.substring(0, 2).toUpperCase();
						// });
						"loading" | "error" | "loaded"; destroy?: (() => void) |
						// });
						undefined;
			};
		}; options?: ToWritableStores<Omit<{ src: string; delayMs: number; loadingStatus?: Writable<"loading" | "error" | "loaded"> | undefined; onLoadingStatusChange: ChangeFn<"loading" | "error" | "loaded"> | undefined; }, "loadingStatus" | "onLoadingStatusChange">>;
	};
    $: {
        avatar = createAvatar({
            src: src,
            delayMs: delayMs,
        });
    }

    // Destructure elements from avatar reactively
    $: image = avatar?.elements?.image;
    $: fallback = avatar?.elements?.fallback;

    // Function to handle mouse enter
  function handleMouseEnter(event: MouseEvent) {
    dispatch('mouseenter',event);
  }

  // Function to handle mouse leave
  function handleMouseLeave(event: MouseEvent) {
    dispatch('mouseleave',event);
  }
  
  </script>
  
<div
on:mouseenter={handleMouseEnter}
on:mouseleave={handleMouseLeave}
role="img"
aria-label="Avatar"
  class="flex items-center {size} justify-center rounded-full large bg-zinc-800"
>
  <img
    use:melt={$image}
    alt="Avatar"
    class="h-full w-full rounded-[inherit]"
  />
  <span use:melt={$fallback} class="text-orange-500 "
    >{text_fallback}</span
  >
</div>

<style>
  .large {
    width: 100px;
    height: 100px;
    font-size: 3rem/* 48px */;
    line-height: 3.75rem/* 60px */;
    font-weight: 500;
  }

  .medium {
    width: 64px;
    height: 64px;
    font-size: 1.875rem/* 30px */;
    line-height: 2.25rem/* 36px */;
    font-weight: 500;
  }

  .small {
    width: 48px;
    height: 48px;
    font-size: 1.3rem/* 24px */;
    line-height: 1.875rem/* 30px */;
    font-weight: 500;
  }

  .smallest {
    width: 40px;
    height: 40px;
    font-size: 1rem/* 16px */;
    line-height: 1.5rem/* 24px */;
    font-weight: 500;
  }
</style>